<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_2">
      내 리뷰 보상 포인트<router-link to="/mypage/my_info"
        ><img src="@/assets/images/sub/back_ico.png" alt=""
      /></router-link>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_box_3">
      <div class="sect_txt">
        상품 등 일부 리뷰 보상 포인트는 지급일 기준 30일 이후부터 램 포인트로
        전환할 수 있습니다(예: 1월 1일 지급 포인트는 3월 1일에).
        <b
          >포인트 지급은 결제일 기준, 포인트 지급 내역 노출은 구매확정일
          기준입니다. 구매 확정이 취소될 경우 지급된 포인트는
          차감(환수)됩니다.</b
        >
        리뷰 보상 포인트는 램 포인트로 전환될 경우 램에서 유통되는 상품 및
        서비스를 구매/이용하는 용도로 사용 가능합니다.(단위:
        포인트,100포인트=1원)
      </div>
      <!-- <div class="sect_box">
        <div class="list">
          <div class="left">현재 내 램 포인트</div>
          <div class="right">총 {{ won(parseInt(reviewPoint)) }}P</div>
        </div>
        <div class="list">
          <div class="left">사용 가능한 포인트</div>
          <div class="right">총 {{ won(parseInt(userPoint)) }}P</div>
        </div>
      </div> -->
      <div class="sect_box_2">
        <div class="box">
          <h4>현재 내 리뷰 포인트는 총</h4>
          <p>
            {{ won(parseInt(reviewPoint) + parseInt(userPoint)) }}<span>P</span>
          </p>
        </div>
        <div class="box">
          <h4>전환 가능한 포인트는</h4>
          <p>{{ won(parseInt(userPoint)) }}<span>P</span></p>
        </div>
      </div>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_box_29">
      <div class="sect_sort">
        <select name="" v-model="sort" @change="getRewardRammPoint(true)">
          <option value="latest">최신순</option>
          <option value="old">오래된순</option>
          <option value="point">포인트순</option>
        </select>
      </div>
      <div class="sect_table">
        <table summary="">
          <colgroup>
            <col width="*" />
            <col width="80px" />
            <col width="80px" />
          </colgroup>
          <thead>
            <tr>
              <th>내역</th>
              <th>포인트</th>
              <th>날짜</th>
            </tr>
          </thead>
          <tbody v-if="listArray">
            <template v-for="list in listArray">
              <!---->
              <tr class="type_1" :key="`${list.seq}_1`">
                <td v-if="list.point_type === 'none'">{{ list.title }}</td>
                <td v-if="list.point_type === 'change'">램 포인트 전환 사용</td>
                <td v-if="list.point_type === 'plus'">포인트 지급</td>
                <td v-if="list.point_type === 'minus'">포인트 차감</td>
                <td>{{ won(parseInt(list.point)) }}</td>
                <td>{{ day(list.reg_dt) }}</td>
              </tr>
              <tr
                class="type_2"
                :key="`${list.seq}_2`"
                v-if="list.point_type === 'none'"
              >
                <td>보낸 사람 : 램</td>
                <td colspan="2">받은 사람 : {{ myInfo.reviewer_nick }}</td>
              </tr>
              <!---->
              <tr
                class="type_3"
                :key="`${list.seq}_3`"
                v-if="list.point_type === 'plus' || list.point_type === 'minus'"
              >
                <td colspan="3">사유: {{ list.memo }}</td>
              </tr>
              <!---->
            </template>
          </tbody>
          <tbody v-else>
            <tr class="none_point">
              <td colspan="3">적립된 포인트가 없습니다.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="rn_more" v-if="parseInt(totalPage) > parseInt(page)">
      <router-link to="" @click.native="getRewardRammPoint()"
        >{{ parseInt(limit) }}개 더보기<img
          src="@/assets/images/sub/my_down_ico.png"
          alt=""
      /></router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      sort: "latest",
      page: 0,
    };
  },
  created() {
    //초기화
    this.$store.dispatch("common/setFooter", {
      mypage: true,
    });
    this.getRewardRammPoint(true);
  },
  computed: {
    ...mapState("mypage", [
      "result",
      "msg",
      "reviewPoint",
      "userPoint",
      "listArray",
      "totalPage",
      "myInfo",
      "checkAuth",
      "limit",
    ]),
  },
  methods: {
    day(str) {
      return this.$moment(str).format("YY.MM.DD");
    },
    async getRewardRammPoint(reset = false) {
      if (reset) {
        this.page = 0;
      }
      await this.$store.dispatch("mypage/getRewardRammPoint", {
        page: this.page,
        sort: this.sort,
        reset,
      });
      this.page++;
    },
    won(str) {
      return this.$won(str);
    },
  },
};
</script>

<style></style>
